import * as React from "react";
import { useTranslation } from "react-i18next";

const BrowserNotSupported: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div style={{ position: "fixed", width: "100%", height: "100%", background: "white", top: 0 }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontWeight: "bold" }}>{t("browserNotSupported.title")}</h1>
        <h3>{t("browserNotSupported.subtitle")}</h3>
      </div>
    </div>
  );
};

export default BrowserNotSupported;
